import React, { FC } from 'react'

import { ImageWithTextEntry as ImageWithTextEntryType } from '@viewlio/types/src/contentful'

import { LocalizedLink } from 'components/common/LocalizedLink'
import { NextGenImage } from 'components/common/NextGenImage'
import styles from 'components/contentful/ImageWithTextEntry/ImageWithTextEntry.module.scss'
import { getAspectRatio } from 'utils/image'

const maxWidths = {
  desktop: 1216,
  mobile: 767,
}

type Props = {
  imageClass?: string
} & ImageWithTextEntryType

const LinkWrapper = ({ children, className, links }) =>
  Array.isArray(links) && links.length > 0
    ? (
      <LocalizedLink href={links[0].url} className={className}>
        {children}
      </LocalizedLink>
    )
    : <>{children}</>

export const ImageLink: FC<Props> = ({
  aboveTheFold,
  altText,
  imageClass,
  desktopImage,
  imagePosition,
  links,
  mobileImage,
}) => {
  const className = imagePosition ? styles[`${imagePosition}Media`] : ''
  return (
    <LinkWrapper
      className={className}
      links={links}
    >
      <NextGenImage
        altText={altText}
        aspectRatio={{
          desktop: getAspectRatio(desktopImage),
          mobile: getAspectRatio(mobileImage),
        }}
        desktopImage={desktopImage}
        imageClass={imageClass}
        lazyLoad={!aboveTheFold}
        maxWidths={maxWidths}
        mobileImage={mobileImage}
        wrapperClass={className}
      />
    </LinkWrapper>
  )
}
